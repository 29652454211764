import DashboardLayout from "@/pages/layouts/DashboardLayout";

let waitingListRoutes = {
  path: '/waiting-lists',
  component: DashboardLayout,
  redirect: '/waiting-lists/list',
  children: [
    {
      path: 'list/:type/:id',
      name: 'Waiting List',
      component: () => import(/* webpackChunkName: "waiting-lists" */  '@/pages/trainings/waitingLists/WaitingListList'),
      meta: {
        permissionsCodes: ['waiting-lists/index'],
      }
    },
  ]
}

export default waitingListRoutes;
