import DashboardLayout from "@/pages/layouts/DashboardLayout";

let trainingCategoryRoutes = {
  path: '/training-categories',
  component: DashboardLayout,
  redirect: '/training-categories/list',
  children: [
    {
      path: 'list',
      name: 'Training Category List',
      component: () => import(/* webpackChunkName: "training-categories" */  '@/pages/trainings/trainingCategories/TrainingCategoryList'),
      meta: {
        permissionsCodes: ['training-categories/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Training Category',
      component: () => import(/* webpackChunkName: "training-categories" */  '@/pages/trainings/trainingCategories/TrainingCategoryForm'),
      meta: {
        permissionsCodes: ['training-categories/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Training Category',
      component: () => import(/* webpackChunkName: "training-categories" */  '@/pages/trainings/trainingCategories/TrainingCategoryForm'),
      meta: {
        permissionsCodes: ['training-categories/update'],
      }
    }
  ]
}

export default trainingCategoryRoutes;
