import DashboardLayout from "@/pages/layouts/DashboardLayout";

let emailTemplateRoutes = {
  path: '/email-templates',
  component: DashboardLayout,
  redirect: '/email-templates/list',
  children: [
    {
      path: 'list',
      name: 'Email Templates List',
      component: () => import(/* webpackChunkName: "email-templates" */  '@/pages/emailTemplates/EmailTemplateList'),
      meta: {
        permissionsCodes: ['email-templates/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Email Templates',
      component: () => import(/* webpackChunkName: "email-templates" */  '@/pages/emailTemplates/EmailTemplateForm'),
      meta: {
        permissionsCodes: ['email-templates/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Email Templates',
      component: () => import(/* webpackChunkName: "email-templates" */  '@/pages/emailTemplates/EmailTemplateForm'),
      meta: {
        permissionsCodes: ['email-templates/update'],
      }
    },
    {
      path: 'preview/:id',
      name: 'Preview Email Templates',
      component: () => import(/* webpackChunkName: "email-templates" */  '@/pages/emailTemplates/EmailTemplatePreview'),
      meta: {
        permissionsCodes: ['email-templates/preview'],
      }
    }
  ]
}

export default emailTemplateRoutes;
