import DashboardLayout from "@/pages/layouts/DashboardLayout";

let trainingSessionRoutes = {
  path: '/training-sessions',
  component: DashboardLayout,
  redirect: '/training-sessions/list',
  children: [
    {
      path: 'list',
      name: 'Training Session List',
      component: () => import(/* webpackChunkName: "training-sessions" */  '@/pages/trainings/trainingSessions/TrainingSessionList'),
      meta: {
        permissionsCodes: ['training-sessions/index'],
      }
    },
    {
      path: 'list/:sessionType?/:training_id?',
      name: 'Training Session List',
      component: () => import(/* webpackChunkName: "training-sessions" */  '@/pages/trainings/trainingSessions/TrainingSessionList'),
      meta: {
        permissionsCodes: ['training-sessions/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Training Session',
      component: () => import(/* webpackChunkName: "training-sessions" */  '@/pages/trainings/trainingSessions/TrainingSessionForm'),
      meta: {
        permissionsCodes: ['training-sessions/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Training Session',
      component: () => import(/* webpackChunkName: "training-sessions" */  '@/pages/trainings/trainingSessions/TrainingSessionForm'),
      meta: {
        permissionsCodes: ['training-sessions/update'],
      }
    },
    {
      path: 'registrations/:type?/:session_id?',
      name: 'Show Registrations',
      component: () => import(/* webpackChunkName: "registrations" */  '@/pages/trainings/Registrations/RegistrationList'),
      meta: {
        permissionsCodes: ['registrations/index'],
      }
    },
    {
      path: 'registrations-logs/:type?/:session_id?',
      name: 'Show Registrations Logs',
      component: () => import(/* webpackChunkName: "registrations" */  '@/pages/trainings/Registrations/RegistrationLogList'),
      meta: {
        permissionsCodes: ['registrations-logs/index'],
      }
    },
    {
      path: 'show-registrations/:id',
      name: 'Show Registration Info',
      component: () => import(/* webpackChunkName: "registrations" */  '@/pages/trainings/Registrations/RegistrationView'),
      meta: {
        permissionsCodes: ['training-sessions/update'],
      }
    },
  ]
}

export default trainingSessionRoutes;
