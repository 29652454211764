import DashboardLayout from "@/pages/layouts/DashboardLayout";

let publicationRoutes = {
  path: '/publications',
  component: DashboardLayout,
  redirect: '/publications/list',
  children: [
    {
      path: 'list',
      name: 'Publication List',
      component: () => import(/* webpackChunkName: "publications" */  '@/pages/publications/publications/PublicationList'),
      meta: {
        permissionsCodes: ['publications/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Publication',
      component: () => import(/* webpackChunkName: "publications" */  '@/pages/publications/publications/PublicationForm'),
      meta: {
        permissionsCodes: ['publications/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Publication',
      component: () => import(/* webpackChunkName: "publications" */  '@/pages/publications/publications/PublicationForm'),
      meta: {
        permissionsCodes: ['publications/update'],
      }
    }
  ]
}

export default publicationRoutes;
