import DashboardLayout from "@/pages/layouts/DashboardLayout";

let trainingCycleRoutes = {
  path: '/training-cycles',
  component: DashboardLayout,
  redirect: '/training-cycles/list',
  children: [
    {
      path: 'list',
      name: 'Training Cycles List',
      component: () => import(/* webpackChunkName: "training-cycles" */  '@/pages/trainings/trainingCycles/TrainingCycleList'),
      meta: {
        permissionsCodes: ['training-cycles/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Training Cycles',
      component: () => import(/* webpackChunkName: "training-cycles" */  '@/pages/trainings/trainingCycles/TrainingCycleForm'),
      meta: {
        permissionsCodes: ['training-cycles/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Training Cycles',
      component: () => import(/* webpackChunkName: "training-cycles" */  '@/pages/trainings/trainingCycles/TrainingCycleForm'),
      meta: {
        permissionsCodes: ['training-cycles/update'],
      }
    },
  ]
}

export default trainingCycleRoutes;
