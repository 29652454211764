import DashboardLayout from "@/pages/layouts/DashboardLayout";

let memberRoutes = {
  path: '/members',
  component: DashboardLayout,
  redirect: '/members/list',
  children: [
    {
      path: 'list',
      name: 'Member List',
      component: () => import(/* webpackChunkName: "members" */  '@/pages/members/MemberList'),
      meta: {
        permissionsCodes: ['members/index'],
      }
    },
    {
      path: 'show/:id',
      name: 'Member Details',
      component:()=>import(/* webpackChunkName: "members" */ '@/pages/members/MemberView'),
      meta: {
        permissionsCodes: ['members/get'],
      }
    },
    {
      path: 'create',
      name: 'Add Member',
      component: () => import(/* webpackChunkName: "members" */  '@/pages/members/MemberForm'),
      meta: {
        permissionsCodes: ['members/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Member',
      component: () => import(/* webpackChunkName: "members" */  '@/pages/members/MemberForm'),
      meta: {
        permissionsCodes: ['members/update'],
      }
    },
    {
      path: 'deleted-members',
      name: 'Deleted Members',
      component:()=>import(/* webpackChunkName: "members" */ '@/pages/members/DeletedMemberList'),
      meta: {
        permissionsCodes: ['members/get'],
      }
    },
  ]
}

export default memberRoutes;
