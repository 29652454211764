import DashboardLayout from "@/pages/layouts/DashboardLayout";

let emailArchiveRoutes = {
  path: '/email-archives',
  component: DashboardLayout,
  redirect: '/email-archives/list',
  children: [
    {
      path: 'list/:type/:id',
      name: 'Email Archives',
      component: () => import(/* webpackChunkName: "email-archives" */  '@/pages/emailTemplates/emailAcrchives/EmailArchiveList'),
      meta: {
        permissionsCodes: ['email-archives/index'],
      }
    },
    {
      path: 'preview/:id',
      name: 'Preview Email Archive',
      component: () => import(/* webpackChunkName: "email-archives" */  '@/pages/emailTemplates/emailAcrchives/EmailArchivePreview'),
      meta: {
        permissionsCodes: ['email-archives/preview'],
      }
    }
  ]
}

export default emailArchiveRoutes;
