import DashboardLayout from "@/pages/layouts/DashboardLayout";

let studyGroupTeamRoutes = {
  path: '/study-group-team',
  component: DashboardLayout,
  redirect: '/study-group-team/list',
  children: [
    {
      path: 'list',
      name: 'Study Group Member List',
      component: () => import(/* webpackChunkName: "Members" */  '@/pages/studyGroups/studyGroupMembers/StudyGroupMemberList'),
      meta: {
        permissionsCodes: ['study-group-team/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Study Group Member',
      component: () => import(/* webpackChunkName: "team" */  '@/pages/studyGroups/studyGroupMembers/StudyGroupMemberForm'),
      meta: {
        permissionsCodes: ['study-group-team/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Study Group Member',
      component: () => import(/* webpackChunkName: "team" */  '@/pages/studyGroups/studyGroupMembers/StudyGroupMemberForm'),
      meta: {
        permissionsCodes: ['study-group-team/update'],
      }
    }
  ]
}

export default studyGroupTeamRoutes;
