import DashboardLayout from "@/pages/layouts/DashboardLayout";

let referralCouponRoutes = {
  path: '/referral-coupons',
  component: DashboardLayout,
  redirect: '/referral-coupons/list',
  children: [
    {
      path: 'list',
      name: 'Referral Coupon List',
      component: () => import(/* webpackChunkName: "referral-coupons" */  '@/pages/coupons/referralCoupons/ReferralCouponList'),
      meta: {
        permissionsCodes: ['referral-coupons/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Referral Coupon',
      component: () => import(/* webpackChunkName: "referral-coupons" */  '@/pages/coupons/referralCoupons/ReferralCouponForm'),
      meta: {
        permissionsCodes: ['referral-coupons/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Referral Coupon',
      component: () => import(/* webpackChunkName: "referral-coupons" */  '@/pages/coupons/referralCoupons/ReferralCouponForm'),
      meta: {
        permissionsCodes: ['referral-coupons/update'],
      }
    }
  ]
}

export default referralCouponRoutes;
