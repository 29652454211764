import DashboardLayout from "@/pages/layouts/DashboardLayout";

let couponRoutes = {
  path: '/coupons',
  component: DashboardLayout,
  redirect: '/coupons/list',
  children: [
    {
      path: 'list',
      name: 'Coupon List',
      component: () => import(/* webpackChunkName: "coupons" */  '@/pages/coupons/CouponList'),
      meta: {
        permissionsCodes: ['coupons/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Coupon',
      component: () => import(/* webpackChunkName: "coupons" */  '@/pages/coupons/CouponForm'),
      meta: {
        permissionsCodes: ['coupons/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Coupon',
      component: () => import(/* webpackChunkName: "coupons" */  '@/pages/coupons/CouponForm'),
      meta: {
        permissionsCodes: ['coupons/update'],
      }
    }
  ]
}

export default couponRoutes;
