import DashboardLayout from "@/pages/layouts/DashboardLayout";

let bookRoutes = {
  path: '/books',
  component: DashboardLayout,
  redirect: '/books/list',
  children: [
    {
      path: 'list',
      name: 'Book List',
      component: () => import(/* webpackChunkName: "books" */  '@/pages/books/BookList'),
      meta: {
        permissionsCodes: ['books/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Book',
      component: () => import(/* webpackChunkName: "books" */  '@/pages/books/BookForm'),
      meta: {
        permissionsCodes: ['books/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Book',
      component: () => import(/* webpackChunkName: "books" */  '@/pages/books/BookForm'),
      meta: {
        permissionsCodes: ['books/update'],
      }
    }
  ]
}

export default bookRoutes;
