import DashboardLayout from '@/pages/layouts/DashboardLayout.vue'
import NotFound from '@/pages/NotFoundPage.vue'
import Overview from '@/pages/Overview.vue'
import Login from '@/pages/authPages/Login.vue';
import rolesRoutes from "@/routes/rolesRoutes";
import permissionsRoutes from "@/routes/permissionsRoutes";
import usersRoutes from "@/routes/usersRoutes";
import branchesRoutes from "@/routes/pages/branchesRoutes";
import pagesRoutes from "@/routes/pages/pagesRoutes";
import redirectionRoutes from "@/routes/pages/redirectionPagesRoutes";
import contentRoutes from "@/routes/pages/contentPagesRoutes";
import panelGroupsRoutes from "@/routes/panels/panelGroupsRoutes";
import panelRoutes from "@/routes/panels/panelRoutes";
import settingsRoutes from "@/routes/general/settingsRoutes";
import UpdatePassword from "@/pages/profile/UpdatePassword";
import UpdateProfile from "@/pages/profile/UpdateProfile";
import ForgetPassword from "@/pages/authPages/ForgetPassword";
import ResetPassword from "@/pages/authPages/ResetPassword";
import newsletterRoutes from "@/routes/general/newsletterRoutes";
import contactusRoutes from "./general/contactusRoutes";
import mediaInfoRoutes from "@/routes/general/mediaInfoRoutes";
import memberRoutes from "./members/memberRoutes";
import teamRoutes from "./team/teamRoutes";
import teamPositionRoutes from "./team/teamPositionRoutes";
import newsCategoryRoutes from "./news/newsCategoryRoutes";
import newsRoutes from "./news/newsRoutes";
import partnerRoutes from "./partners/partnerRoutes";
import trainingCategoryRoutes from "./trainings/trainingCategoryRoutes";
import trainingCycleRoutes from "./trainings/trainingCycleRoutes";
import trainingRoutes from "./trainings/trainingRoutes";
import trainingSessionRoutes from "./trainings/trainingSessionRoutes";
import bookRoutes from "./books/bookRoutes";
import testimonialRoutes from "./testimonials/testimonialRoutes";
import memberTypeRoutes from "./members/memberTypeRoutes";
import couponRoutes from "./coupons/couponRoutes";
import referralCouponRoutes from "./coupons/referralCouponRoutes";
import studyGroupTeamRoutes from "./studyGroups/studyGroupTeamRoutes";
import authorRoutes from "./authors/authorRoutes";
import publicationRoutes from "./publications/publicationRoutes";
import publicationCategoryRoutes from "./publications/publicationCategoryRoutes";
import newsletterTemplateRoutes from "./general/newsletterTemplateRoutes";
import emailTemplateRoutes from "./emailTemplates/emailTemplateRoutes";
import waitingListRoutes from "./trainings/waitingListRoutes";
import emailArchiveRoutes from "./emailTemplates/emailArchiveRoutes";



let dashboardRoutes = {
  path: '/',
  component: DashboardLayout,
  redirect: '/overview',
  children: [
    {
      path: 'overview',
      name: 'Overview',
      component: Overview,
    },
    {
      path: 'change-my-password',
      name: 'Update Password',
      component: UpdatePassword,
    },
    {
      path: 'update-profile',
      name: 'Update Profile',
      component: UpdateProfile,
    },

  ],

};

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
};
let forgetPasswordPage = {
  path: '/forget-password',
  name: 'Forget Password',
  component: ForgetPassword
};

let resetPassword = {
  path: '/reset-password/:token',
  name: 'Reset Password',
  component: ResetPassword
};

const routes = [
  {
    path: '/',
    redirect: '/overview'
  },
  loginPage,
  forgetPasswordPage,
  resetPassword,
  rolesRoutes,
  permissionsRoutes,
  redirectionRoutes,
  usersRoutes,
  branchesRoutes,
  contentRoutes,
  pagesRoutes,
  panelGroupsRoutes,
  panelRoutes,
  settingsRoutes,
  newsletterRoutes,
  contactusRoutes,
  dashboardRoutes,
  mediaInfoRoutes,
  memberRoutes,
  teamRoutes,
  teamPositionRoutes,
  newsCategoryRoutes,
  newsRoutes,
  partnerRoutes,
  trainingCategoryRoutes,
  trainingCycleRoutes,
  trainingRoutes,
  trainingSessionRoutes,
  bookRoutes,
  testimonialRoutes,
  studyGroupTeamRoutes,
  memberTypeRoutes,
  couponRoutes,
  referralCouponRoutes,
  authorRoutes,
  publicationRoutes,
  publicationCategoryRoutes,
  newsletterTemplateRoutes,
  emailTemplateRoutes,
  waitingListRoutes,
  emailArchiveRoutes,
  {path: '*', name: 'Not Found', component: NotFound}
]

export default routes
