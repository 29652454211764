import DashboardLayout from "@/pages/layouts/DashboardLayout";

let teamPositionRoutes = {
  path: '/team-positions',
  component: DashboardLayout,
  redirect: '/team-positions/list',
  children: [
    {
      path: 'list',
      name: 'Team Position List',
      component: () => import(/* webpackChunkName: "Team-positions" */  '@/pages/team/teamPositions/TeamPositionList'),
      meta: {
        permissionsCodes: ['team-positions/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Team Position',
      component: () => import(/* webpackChunkName: "team-positions" */  '@/pages/team/teamPositions/TeamPositionForm'),
      meta: {
        permissionsCodes: ['team-positions/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Team Position',
      component: () => import(/* webpackChunkName: "team-positions" */  '@/pages/team/teamPositions/TeamPositionForm'),
      meta: {
        permissionsCodes: ['team-positions/update'],
      }
    }
  ]
}

export default teamPositionRoutes;
