import DashboardLayout from "@/pages/layouts/DashboardLayout";

let authorRoutes = {
  path: '/authors',
  component: DashboardLayout,
  redirect: '/authors/list',
  children: [
    {
      path: 'list',
      name: 'Author List',
      component: () => import(/* webpackChunkName: "authors" */  '@/pages/publications/authors/AuthorList'),
      meta: {
        permissionsCodes: ['authors/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Author',
      component: () => import(/* webpackChunkName: "authors" */  '@/pages/publications/authors/AuthorForm'),
      meta: {
        permissionsCodes: ['authors/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Author',
      component: () => import(/* webpackChunkName: "authors" */  '@/pages/publications/authors/AuthorForm'),
      meta: {
        permissionsCodes: ['authors/update'],
      }
    }
  ]
}

export default authorRoutes;
