import DashboardLayout from "@/pages/layouts/DashboardLayout";

let trainingRoutes = {
  path: '/trainings',
  component: DashboardLayout,
  redirect: '/trainings/list',
  children: [
    {
      path: 'list',
      name: 'Training List',
      component: () => import(/* webpackChunkName: "trainings" */  '@/pages/trainings/trainings/TrainingList'),
      meta: {
        permissionsCodes: ['trainings/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Training',
      component: () => import(/* webpackChunkName: "trainings" */  '@/pages/trainings/trainings/TrainingForm'),
      meta: {
        permissionsCodes: ['trainings/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Training',
      component: () => import(/* webpackChunkName: "trainings" */  '@/pages/trainings/trainings/TrainingForm'),
      meta: {
        permissionsCodes: ['trainings/update'],
      }
    },
    {
      path: 'payment',
      name: 'Edit Training',
      component: () => import(/* webpackChunkName: "trainings" */  '@/pages/trainings/trainings/PaymentForm'),
      meta: {
        permissionsCodes: ['trainings/update'],
      }
    }
  ]
}

export default trainingRoutes;
