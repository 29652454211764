import DashboardLayout from "@/pages/layouts/DashboardLayout";

let newsletterTemplateRoutes = {
  path: '/newsletter-templates',
  component: DashboardLayout,
  redirect: '/newsletter/list',
  children: [
    {
      path: 'list',
      name: 'Newsletter Template List',
      component:()=>import(/* webpackChunkName: "newsletter" */ '@/pages/general/newsletter/newsletterTemplates/NewsletterTemplateList'),
      meta: {
        permissionsCodes: ['newsletter-templates/index'],
      }
    },
    {
      path: 'create',
      name: 'Create Template',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/general/newsletter/newsletterTemplates/NewsletterTemplateForm'),
      meta: {
        permissionsCodes: ['newsletter-templates/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Template',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/general/newsletter/newsletterTemplates/NewsletterTemplateForm'),
      meta: {
        permissionsCodes: ['newsletter-templates/update'],
      }
    },
    {
      path: 'show/:id',
      name: 'Show Template',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/general/newsletter/newsletterTemplates/NewsletterTemplateView'),
      meta: {
        permissionsCodes: ['newsletter-templates/get'],
      }
    }
  ]
}

export default newsletterTemplateRoutes;
