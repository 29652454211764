import DashboardLayout from "@/pages/layouts/DashboardLayout";

let testimonialRoutes = {
  path: '/testimonials',
  component: DashboardLayout,
  redirect: '/testimonials/list',
  children: [
    {
      path: 'list',
      name: 'Testimonial List',
      component: () => import(/* webpackChunkName: "testimonials" */  '@/pages/testimonials/TestimonialList'),
      meta: {
        permissionsCodes: ['testimonials/index'],
      }
    },
    {
      path: 'list/:training_id',
      name: 'Testimonial List',
      component: () => import(/* webpackChunkName: "testimonials" */  '@/pages/testimonials/TestimonialList'),
      meta: {
        permissionsCodes: ['testimonials/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Testimonial',
      component: () => import(/* webpackChunkName: "testimonials" */  '@/pages/testimonials/TestimonialForm'),
      meta: {
        permissionsCodes: ['testimonials/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Testimonial',
      component: () => import(/* webpackChunkName: "testimonials" */  '@/pages/testimonials/TestimonialForm'),
      meta: {
        permissionsCodes: ['testimonials/update'],
      }
    }
  ]
}

export default testimonialRoutes;
