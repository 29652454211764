import DashboardLayout from "@/pages/layouts/DashboardLayout";

let publicationCategoryRoutes = {
  path: '/publication-categories',
  component: DashboardLayout,
  redirect: '/publication-categories/list',
  children: [
    {
      path: 'list',
      name: 'Publication Category List',
      component: () => import(/* webpackChunkName: "publication-categories" */  '@/pages/publications/publicationCategories/PublicationCategoryList'),
      meta: {
        permissionsCodes: ['publication-categories/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Publication Category',
      component: () => import(/* webpackChunkName: "publication-categories" */  '@/pages/publications/publicationCategories/PublicationCategoryForm'),
      meta: {
        permissionsCodes: ['publication-categories/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Publication Category',
      component: () => import(/* webpackChunkName: "publication-categories" */  '@/pages/publications/publicationCategories/PublicationCategoryForm'),
      meta: {
        permissionsCodes: ['publication-categories/update'],
      }
    }
  ]
}

export default publicationCategoryRoutes;
