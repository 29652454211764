var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{'nav-open': _vm.$sidebar.showSidebar}},[_c('notifications'),_c('side-bar',{scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Dashboard'), icon: 'nc-icon nc-chart-pie-35', path: '/training-sessions/registrations/all'}}}),(_vm.$store.getters['auth/haveOneOfPermissions']
                       (['members/index', 'member-types/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Members'), icon: 'nc-icon nc-single-02'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['members/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Members'), path: '/members'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['member-types/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Member Types'), path: '/member-types'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                       (['coupons/index', 'referral-coupons/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Coupons'), icon: 'nc-icon nc-money-coins'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['coupons/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Coupons'), path: '/coupons'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['referral-coupons/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Referral Coupons'), path: '/referral-coupons'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                       (['registrations/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Registrations'), icon: 'nc-icon nc-credit-card'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['registrations/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Sessions'), path: '/training-sessions/registrations/session'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['registrations/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Cycles'), path: '/training-sessions/registrations/cycle'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                       (['training-sessions/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Sessions'), icon: 'nc-icon nc-button-play'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['training-sessions/index']))?_c('sidebar-item',{staticClass:"no-transform",attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Incoming Sessions'), path: '/training-sessions/list/incoming'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['training-sessions/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Past Sessions'), path: '/training-sessions/list/past'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['training-sessions/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('All Sessions'), path: '/training-sessions/list/all'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                       (['training-categories/index', 'trainings/index', 'training-cycles/index' ]))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Trainings'), icon: 'nc-icon nc-layers-3'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['trainings/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Trainings'), path: '/trainings/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['training-categories/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Training Categories'), path: '/training-categories/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['training-cycles/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Training Cycle'), path: '/training-cycles/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                       (['team-positions/index', 'team/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Team'), icon: 'nc-icon nc-badge'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['team/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Team'), path: '/team/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['team-positions/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Team Positions'), path: '/team-positions/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['study-group-team/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Study Group'), icon: 'nc-icon nc-ruler-pencil'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['study-group-team/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Members'), path: '/study-group-team/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                       (['publication-categories/index', 'publications/index', 'authors/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Publications'), icon: 'nc-icon nc-light-3'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['publications/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Publications'), path: '/publications/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['publication-categories/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Categories'), path: '/publication-categories/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['authors/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Authors'), path: '/authors/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                       (['news-categories/index', 'news/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('News'), icon: 'nc-icon nc-paper-2'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['news/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('News'), path: '/news/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['news-categories/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Categories'), path: '/news-categories/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['email-templates/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Email Templates'), icon: 'nc-icon nc-puzzle-10', path: '/email-templates'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['partners/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Partners'), icon: 'nc-icon nc-puzzle-10', path: '/partners'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['testimonials/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Testimonials'), icon: 'nc-icon nc-satisfied', path: '/testimonials'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                            (['newsletter/index',
                                             'newsletter-templates/index' ]))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Newsletter'), icon: 'nc-icon nc-email-83'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['newsletter/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Subscribers'), path: '/newsletter/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                            (['pages/index',
                                             'content-pages/create',
                                             'redirection-pages/create',
                                             'branches/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Pages'), icon: 'nc-icon nc-single-copy-04'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['pages/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Existing Pages'), path: '/pages/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['content-pages/create']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Add Content Page'), path: '/content-pages/create'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['redirection-pages/create']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Add Redirection Page'), path: '/redirection-pages/create'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['branches/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Branches'), path: '/branches/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                       (['panel-groups/index','panels/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Panels And Banners'), icon: 'nc-icon nc-album-2'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['panels/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Panels'), path: '/panels/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['panel-groups/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Groups'), path: '/panel-groups/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['books/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Books'), icon: 'nc-icon nc-single-copy-04', path: '/books'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['settings/index']))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Settings'), icon: 'nc-icon nc-settings-gear-64', path: '/settings/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('roles/index')
                            // || $store.getters['auth/checkAccess']('permissions/index')
                            || _vm.$store.getters['auth/checkAccess']('users/index')
                            || _vm.$store.getters['auth/checkAccess']('users/deleted-users'))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Access Management'), icon: 'nc-icon nc-circle-09'}}},[(_vm.$store.getters['auth/checkAccess']('roles/index'))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Roles'), path: '/roles/list'}}}):_vm._e(),(false)?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Permissions'), path: '/permissions/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('users/index'))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Users'), path: '/users/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('users/deleted-users'))?_c('sidebar-item',{attrs:{"link":{name: _vm.$store.getters['languageMixin/getStringFromLanguage']('Deleted Users'), path: '/users/DeletedUsersList'}}}):_vm._e()],1):_vm._e()]}}])},[_c('user-menu'),_c('mobile-menu')],1),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar($event)}}}),_c('content-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }